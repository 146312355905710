import React, { useEffect, useState } from "react"
import Hero from '../components/Hero';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Counter from '../components/Counter';
import Section from '../components/Section';
import Milestones from "../components/Milestones";
import _ from 'lodash';
import PurchaseButtons from '../components/PurchaseButtons';

import firebase from '../services/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { fetch, clearRedirectData, fetchProfile } from '../store/actions/info'; 
import { setIsAuthenticated, setUser, init as initAuth, logout, signIn } from '../store/actions/auth'; 
import Layout from "../components/layout";

const MILESTONES_DATA = [
  {title: 'Pre\u2011purchase\nBegins', count: '1', now: true},
  {title: 'We apply to list ELON on exchanges', count: '1,000,000', now: false},
  {title: 'Price Change to $1/ELON', count: '69,000,000', now: false},
  {title: 'launch of investment program for solar projects', count: '100,000,000', now: false},
  {title: 'launch sustainable housing and related investments', count: '300,000,000', now: false},
]

export default function Home({ location }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const total = useSelector(state => state.info.total);
  const price = useSelector(state => state.info.price);
  const loadingInfo = useSelector(state => state.info.isLoading);
  // const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  // const isAuthLoading = useSelector(state => state.auth.isLoading);
  const redirectData = useSelector(state => state.info.redirectData);
  // const userEmail = useSelector(state => state.info.email);
  // const profileCoins = useSelector(state => state.info.profileCoins);
  useEffect(() => {
      dispatch(fetch());
      dispatch(initAuth());

      firebase.init();

      if(redirectData && !!redirectData.withAuth){
        setShowModal(true);
        clearRedirectData();
      }
      firebase.instance.auth().onAuthStateChanged(function(user) {
        if (user) {
          dispatch(setIsAuthenticated(true));
          dispatch(fetchProfile());
        } else {
          dispatch(setUser(null));
          dispatch(setIsAuthenticated(false));
        }
      });

  }, []);
  const formattedPrice = price ? (parseFloat(price) / 100).toFixed(2) : null; 

  return (
    <>
      <Layout>
      <div className="home">
          <Hero>              
              <Hero.Title>✋💎 ELON COIN™ 💎 ✋</Hero.Title>
              <Hero.SubTitle >a crypto-collectible coin</Hero.SubTitle>
              <Hero.Desc>
                there are currently&nbsp;
                {loadingInfo || total === null ? <Spinner size='sm' animation="border" /> : <Counter startValue={total}/>} 
                &nbsp;of 69 million remaining at <br/> 
                {loadingInfo ? <Spinner size='sm' animation="border" /> : ' $'+formattedPrice}/ELON
                before the planned price increase to $1.00/ELON or market rates
                </Hero.Desc>
              <Hero.Buttons>
                <PurchaseButtons openAuth={() => setShowModal(true)} />
              </Hero.Buttons>
          </Hero>
          <Container>
              <section className="section my-5 text-center">
                <h3 className="section__title">What can i do with ELON?</h3>
                <p className="section__body">ELON is a limited edition crypto-collectible coin. You are free to buy, sell, or hold just like most other coins. You can visit the “my wallet” tab to view your current holdings.  Our team is currently working on the smart contracts to allow transfer of ELON COIN™ to your personal wallet and trading.  When this is complete, you will be sent an email with instructions to transfer your ELON to a personal wallet or exchange. Our team will apply for exchange listing in the upcoming days, and we have agreed to complete an application on or before we complete the sales of 1M pre-purchase coins.</p>
                <p className="section__body">ELON is an Ethereum sidechain token.</p>
              </section>
              <h2 className="text-center text-light mb-4">MILESTONES</h2>
              <Milestones data={MILESTONES_DATA} />
              <div className="text-light mt-3 font-weight-bold milestones__tip">
                &nbsp;&nbsp;* not to scale
                <br/>
                ** subject to market costs
              </div>
              <Section 
                center
                title="Where did the name come from?" 
                body="The name is not based on any person, place, or thing. we just really like the name.">
              </Section>
              <Section
                center 
                title="Who are we?" 
                body="ELON COIN™ was created by Greener, a technology company founded in 2019 to support technology projects that help the environment. Based in Lexington, South Carolina, Greener has been working on a mobile application that would allow individual consumers to easily be matched with renewable, carbon, and other environmental offsets to balance their own household and personal emissions. We have pledged to invest 95% of our ELON pre-purchase proceeds to projects in solar, renewables, and sustainable affordable housing – effectively everything that is generated minus our costs of operating.">
              </Section>
              <Section 
                center
                title="From our CEO:" 
                body="“Our team is just two nerds who care about our planet, with a little help from our friends.  We have started this project with the hope of making a lasting impact in solar, renewables, and sustainable affordable housing.  We have pledged to give 95% of our pre-purchase proceeds to projects in these fields – effectively everything we generate minus our costs of operating and credit card fees. My co-founder and I have also pledged to make this the continued focus of the company and personal proceeds of Elon Coin.  We believe that Elon Coin will be sought as a collectible of value and a benefit to our community and our planet.”">
              </Section>
          </Container>
      </div>
        </Layout>
    </>
  )
}
